import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "./../../helpers/useWindowSize";
import { useSelector } from "react-redux";

// Styles
import "./styles.scss";
import { Layout } from "../../layout";

const ThankYou = () => {
  const navigate = useNavigate();
  const formStore = useSelector((state) => state.form);

  const size = useWindowSize();
  const mobileL = 630;

  console.log(formStore);

  return (
    <Layout>
      <div className="section-thankyou">
        <div className="wrapper">
          <div className="container-thankyou">
            {size.width <= mobileL ? (
              <>
                <h2>Thank you, {formStore.firstName}!</h2>
                <p>
                  Our specialist team has now received your claim for
                  compensation.
                </p>
                <br />
                <p>
                  We will review your information and one of our advisors will
                  be in touch soon to discuss what will happen next.
                </p>
                <button
                  className="btn-form primary"
                  onClick={() => navigate("/")}
                >
                  RETURN TO HOME
                </button>
              </>
            ) : (
              <>
                <h2>Thank you, {formStore.firstName}!</h2>
                <p>
                  Our specialist team has now received your claim for
                  compensation. We will review your information and one of our
                  advisers will be in touch soon to discuss what will happen
                  next.
                </p>
                {/* {formStore.sourceRef !== "DWO" && formStore?.ref2 !== "DWO" && (
                  <div className="second-title-thankyou">
                    <h3>Whilst you’re here...</h3>
                    <h4>You could qualify to write your debts off</h4>
                    <p>
                      It only takes seconds to check if you’re eligible below...
                    </p>
                  </div>
                )} */}
              </>
            )}
          </div>
          {/* <div className="redirect-container">
            {formStore.sourceRef !== "DWO" && formStore?.ref2 !== "DWO" && (
              <div className="container__left">
                <img src={FdSvg} alt="Finance Dispute" />
                <h3>Are you looking to write off your debt?</h3>
                <button
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_DWO_URL}?id=${formStore.leadId}&ref=pcp&ref2=${formStore.sourceRef}`
                    );
                  }}>
                  START MY CLAIM
                </button>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default ThankYou;