import {
  UPDATE_STEP,
  RESET_STEP,
} from "../types/step.types";

const initialStep = 1;

export function stepReducer(
  state = initialStep,
  action,
) {
  switch (action.type) {
    case UPDATE_STEP:
      return action.payload;
    case RESET_STEP:
      return initialStep;
    default:
      return state;
  }
}
