import {
  UPDATE_FORM,
  RESET_FORM,
} from "../types/form.types";

const initialState = {
  financeYear: "",
  lender: "",
  postcode: "",
  addressLineOne: "",
  city: "",
  county: "",
  previousAddress: [],
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  carColour: "",
  yearOfManufacture: "",
  signature: "",
  title: "",
  dob: "",
  dd: "",
  mm: "",
  yyyy: "",
};

export function formReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_FORM:
      return { ...action.payload };
    case RESET_FORM:
      return { ...initialState };
    default:
      return state;
  }
}
