import { UPDATE_FORM, RESET_FORM } from "../types/form.types";

// UPDATE ENTIRE FORM
export const updateFormAction = (payload) => {
  return { type: UPDATE_FORM, payload: payload };
};

export const resetFormAction = (payload) => {
  return { type: RESET_FORM, payload: payload };
};
