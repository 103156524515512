
// Components
import { Layout } from "../../layout"
import BenefitsBanner from "./components/benefitsBanner";
import Hero from "./components/hero";
import HowDoI from "./components/howDoI";
import WhatIs from "./components/whatIs";
import FAQs from './components/FAQs';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetFormAction } from "../../redux/actions/form.actions";
import { updateStep } from "../../redux/actions/step.actions";



export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFormAction(true));
    dispatch(updateStep(1));
  }, []);

  return (
    <Layout>
      <Hero />
      <BenefitsBanner />
      <WhatIs />
      <HowDoI />
      <FAQs />
    </Layout>
  );
}