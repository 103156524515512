import {
  UPDATE_STEP,
  RESET_STEP,
} from "../types/step.types";

const updateStepAction = (payload) => {
  return { type: UPDATE_STEP, payload: payload };
};

const resetStepAction = () => {
  return { type: RESET_STEP };
};

export function updateStep(payload) {
  return function (dispatch) {
    dispatch(updateStepAction(payload));
  };
}

export function resetStep() {
  return function (dispatch) {
    dispatch(resetStepAction());
  };
}
