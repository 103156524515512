import React, { useEffect, useState } from "react";

//Styles
import "./styles.scss";
import { useSelector } from "react-redux";

const Progression = () => {
  const stepStore = useSelector((state) => state.step);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setCurrentStep(stepStore);
  }, [stepStore]);

  return (
    <div className="progressBar">
      <div className={currentStep >= 1 ? "active" : "inactive"}>
        <span>1.</span>
      </div>
      <div className={currentStep >= 2 ? "active" : "inactive"}>
        <span>2.</span>
      </div>
      <div className={currentStep >= 3 ? "active" : "inactive"}>
        <span>3.</span>
      </div>
      <div className={currentStep >= 4 ? "active" : "inactive"}>
        <span>4.</span>
      </div>
    </div>
  );
};

export default Progression;
