// Components
import { useNavigate } from "react-router-dom";

// Styles
import axios from "axios";
import { Loading, Notify } from "notiflix";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EuIcon } from "../../../../assets/icons/eu.svg";
import { env } from "../../../../env";
import { updateFormAction } from "./../../../../redux/actions/form.actions";
import { updateStep } from "./../../../../redux/actions/step.actions";
import "./styles.scss";

const Hero = () => {
  const formStore = useSelector((store) => store.form);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registration, setRegistration] = useState("");

  const onSubmit = async () => {
    Loading.dots();

    await axios
      .get(
        `https://www.regcheck.org.uk/api/reg.asmx/Check?RegistrationNumber=${registration}&username=${env.REACT_APP_USERNAME_REGCHECK}`
      )
      .then(({ data }) => {
        console.log(data);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");

        const attribute = xmlDoc
          .getElementsByTagName("Vehicle")[0]
          .getElementsByTagName("vehicleJson")[0];
        const result = JSON.parse(attribute.textContent);
        dispatch(
          updateFormAction({
            ...formStore,
            registration: registration,
            vehicleType: result.MakeDescription.CurrentTextValue,
            carColour: result.Colour,
            yearOfManufacture: result.RegistrationYear,
          })
        );
        dispatch(updateStep(1));
        Loading.remove();
        navigate("/form");
      })
      .catch(() => {
        Notify.warning("Unable to verify registration details.", {
          position: "center-top",
        });
        Loading.remove();
      });
  };

  return (
    <section className="header-home">
      <div className="wrapper">
        <div className="content">
          <div className="content-left">
            <div className="top-content">
              <h1>Have you had a car on finance?</h1>
              <p>
                You could be owed an average of <strong>£4,500</strong> in
                compensation…
              </p>
            </div>
            <div className="form">
              <p>Check for free now here:</p>
              <div className="form-vehicule">
                <div className="wrapper-input">
                  <div className="input">
                    <div className="input-flag">
                      <EuIcon />
                      <p>GB</p>
                    </div>
                    <input
                      onChange={(e) => {
                        setRegistration(e.target.value);
                      }}
                      value={registration}
                      placeholder="ENTER REG"
                    />
                  </div>
                </div>
                <button className="btn-home" onClick={onSubmit}>
                  START YOUR FREE CHECK
                </button>
              </div>
            </div>
          </div>
          <div className="content-right" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
