import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../layout";
import "./styles.scss";

const Sorry = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="section-sorry">
        <div className="wrapper">
          <div className="container-sorry">
            <h2>We’re sorry!</h2>
            <p>
              Based on the information you have given us, it’s unlikely you will
              be able to launch a claim using Online PCP Checker.
            </p>
            <p>
              Please fill in the form again if you believe you made a mistake
              when providing your information.
            </p>
            <button className="btn-form primary" onClick={() => navigate("/")}>
              RETURN TO HOME
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Sorry;
