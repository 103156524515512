import React, { useEffect, useState } from "react";

// Styles
import "./styles.scss";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useSelector } from "react-redux";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";

const Steps = () => {
const stepStore = useSelector((state) => state.step);
const [currentStepIndex, setCurrentStepIndex] = useState(1);

useEffect(() => {
  const index = stepStore - 1;
  setCurrentStepIndex(index);
  console.log(currentStepIndex);
}, [stepStore]);

const stepArray = [
    <StepOne />,
    <StepTwo />,
    // <StepThree />,
    // <StepFour />,
    <StepFive />,
    // <StepSix />,
    <StepSeven />,
  ];

  return <div className="step-wrapper">{stepArray[currentStepIndex]}</div>;
};

export default Steps;
