import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


// Components
import arrow from "../../../../../assets/icons/drop-arrow.svg";
import { Notify } from "notiflix";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "../../../../../redux/actions/step.actions";
import { updateFormAction } from "./../../../../../redux/actions/form.actions";

// Styles
import "../styles.scss";

import { years } from "./data";

const StepTwo = () => {
  const dispatch = useDispatch();

  const formStore = useSelector((store) => store.form);
  const [formData, setFormData] = useState({ financeYear: "" });

  const handleChange = (e) => {
    let value = e.target.value;
    setFormData({ financeYear: value });
    dispatch(updateFormAction({ ...formStore, financeYear: value }));
  };

  const handleNext = () => {
    if (formData.financeYear.length === 0) {
      Notify.init({ position: "center-top" });
      return Notify.failure("Please select a year from the list.");
    } else {
      dispatch(updateStep(3));
    }
  };

  return (
    <div className="step">
      <div className="two-inner">
        <p className="two-title"> When was the finance taken out?</p>
        <div className="two-subTitle">Select the year:</div>
        <div className="dropdown-wrapper">
          <select
            name="financeYear"
            id="financeYear"
            className="two-dropdown"
            onChange={handleChange}
          >
            <option value={`${formData.financeYear}`} disabled selected>
              {formData.financeYear ? formData.financeYear : "YYYY"}
            </option>
            {years.map((y, i) => {
              return (
                <option key={i} value={y}>
                  {y}
                </option>
              );
            })}
          </select>
          <img src={arrow} alt="arrow" />
        </div>
        <div className="btn-wrapper">
          <button className="btn-form tertiary" onClick={handleNext}>
            NEXT QUESTION
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
