import React from "react";

// Assets
import clockSvg from "../../../../assets/icons/benefits-clock.svg";
import internetSvg from "../../../../assets/icons/benefits-internet.svg";
import documentsSvg from "../../../../assets/icons/benefits-documents.svg";
import handshakeSvg from "../../../../assets/icons/benefits-handshake.svg";

//styles
import "./styles.scss";


export const BenefitsBanner = () => {
  const componentData = [
    {
      icon: clockSvg,
      title: "Returns results in under a minute",
    },
    {
      icon: handshakeSvg,
      title: "Can’t claim? Don’t pay us a penny",
    },
    {
      icon: internetSvg,
      title: "100% online process",
    },
    {
      icon: documentsSvg,
      title: "No additional documents",
    },
  ];

  return (
    <section className="section-benefits">
      <div className="wrapper">
        <ul>
          {componentData.map((comp, i) => {
            return (
              <li key={i}>
                <img src={comp.icon} alt="list-icon" />
                <p>{comp.title}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default BenefitsBanner;
