
// Components
import { useSelector } from 'react-redux';
import { Layout } from './../../layout/index';
import Progression from './components/progression';
import Steps from './components/steps';

// Styles
import "./styles.scss";

const Form = () => {

  const stepStore = useSelector((state) => state.step);

  return (
    <Layout>
      <section className="section-form">
        <div className="wrapper">
          <div className="form-container">
            <div
              className="form-progress"
              style={
                stepStore >= 4 ? { display: "none" } : { display: "block" }
              }
            >
              <Progression />
            </div>
            <div className="form-steps">
              <Steps />
            </div>
            <div className='bottom-banner'>
              <p>
                Average Claim: <strong>£4,500</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Form;