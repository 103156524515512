import React from "react";

// Styles
import "./styles.scss";

const HowDoI = () => {
  const contentData = [
    {
      bg: "1.",
      body: "Simply fill in our quick, easy-to-complete form",
    },
    {
      bg: "2.",
      body: "Our specialist team will review your case",
    },
    {
      bg: "3.",
      body: "Find out if your claim is successful, and await your payment",
    },
  ];
  return (
    <section className="howDoI-section">
      <div className="wrapper">
        <h2>How do I know I’m eligible?</h2>
        <ul className="howDoI-content">
          {contentData.map((obj, i) => {
            return (
              <li key={i}>
                <p>{obj.bg}</p>
                <p>{obj.body}</p>
              </li>
            );
          })}
        </ul>
        <button className="btn-home" onClick={() => window.scrollTo(0, 0)}>
          START YOUR FREE CHECK
        </button>
      </div>
    </section>
  );
};

export default HowDoI;
