export const lendersData = [
  "FCE Bank Plc",
  "Moneybarn No. 1 Limited",
  "FirstRand Bank Limited",
  "Volkswagen Financial Services (UK) Limited",
  "Black Horse",
  "Vauxhall Finance plc",
  "Santander Consumer (UK) plc",
  "Hyundai Capital UK Ltd",
  "Motonovo LTD",
  "BMW Financial Services (GB) Limited",
  "Honda Finance Europe Plc",
  "Close Brothers Limited",
  "Stellantis Financial Services UK Limited",
  "Santander Motor Finance",
  "Startline Motor Finance Limited",
  "FGA Capital UK Ltd",
  "Renault - RCI Financial Services Limited",
  "Ford Credit",
  "Alphera Limited",
  "Advantage Finance Limited",
  "Clydesdale Financial Services Limited",
  "Fortis Lease",
  "Nissan Finance (GB) Limited",
  "Oodle Financial Services Limited",
  "Alphabet Finance",
  "Fiat Auto Financial Services Ltd",
  "GMAC",
  "MI Vehicle Finance Limited",
  "Mercedes-Benz Financial Services UK Limited",
  "FCA Automotive Services Ltd",
  "Aqua Finance",
  "Norwich Union",
  "Arnold Clarke Finance Ltd",
  "FCA Automotive Services UK Ltd",
  "Secure Trust Bank",
  "Carland of Wolverhampton",
  "PSA Finance UK Limited",
  "Morgan Stanley",
  "Hitachi Capital",
  "BMW Finance",
  "Kia Finance",
  "Lloyds Banking Group",
  "Family Finance Limited",
  "Blue Motor Finance Ltd",
  "Toyota Financial Services",
  "Ocean",
  "Santander Plc",
  "Suzuki Finance GB Ltd",
  "Audi Finance",
  "Marsh Finance Ltd",
  "Land Rover Finance",
  "Secure Trust Bank Public Limited Company",
  "Go Car Credit Ltd",
  "Bank Of Scotland plc",
  "PCF Bank Limited",
  "The Car Finance Company Ltd",
  "Billing Finance Limited",
  "Barclays Bank UK Plc",
  "FirstRand Bank Ltd",
  "NIIB Group Ltd",
  "Mitsubishi HC Capital UK Plc",
  "ALD Automotive",
  "Specialist Motor Finance Limited",
  "RCI Financial Services Limited",
  "1st Stop Finance Ltd",
  "Paragon Banking Group PLC",
  "Peugeot Financial Services",
  "Close Motor Finance",
  "Private & Commercial Finance Group plc",
  "GE Money",
  "Tandem Motor Finance Limited",
  "Santander UK PLC",
  "Mann Island Finance Limited",
  "International Motors Finance Limited",
  "247 Money Group Limited",
  "Northridge Finance",
  "Citroen Financial Services",
  "CA Auto Finance UK Ltd",
  "Raphaels Bank",
  "Conister Bank Limited",
  "Vehicle Credit Limited",
  "Oplo CF Ltd",
  "Shogun Finance Ltd",
  "Suzuki Financial Services Limited",
  "Hyundai Car Finance Limited",
  "Aldermore Bank PLC",
  "ING Lease (UK) Limited",
  "LeasePlan Finance Limited",
  "Retail Money Market Ltd",
  "The Funding Corporation",
  "Creation Consumer Finance",
  "ACF Car Finance Limited",
  "SEAT Finance",
  "1st Stop Finance",
  "Advantage Finance",
  "Perrys Vauxhall",
  "Bristol St Group Ltd",
  "Whichdeal Ltd",
  "Lombard North Central",
  "Automotive Financial Services Ltd",
  "Advanced Payment Solutions Limited",
  "J.D. Williams & Company Limited",
  "A1 Mortgages",
  "24 Studio",
  "Asda Money",
  "ARG Personal Loans Ltd",
  "Carfinance247 Limited",
  "Lombard Bank Limited",
  "Decidebloom Limited",
  "Hartwell Kidlington Ford",
  "MG Financial Services GB Ltd",
  "Paragon Personal Finance",
  "Ald Automotive Limited",
  "Burnley Savings and Loans Limited",
  "Midland Mercantile Finance Limited",
  "Penrican Credit Limited",
  "Premium Plan Limited",
  "GE Capital",
  "Auto Accept Finance Limited",
  "AutoMoney Limited",
  "BNP Paribas Leasing Solutions Limited",
  "Ratesetter Motor Limited",
  "Admiral Financial Services Ltd",
];
