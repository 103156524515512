import { Notify } from "notiflix";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import SignatureCanvas from "react-signature-canvas";
import { Layout } from "../../layout";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  resetFormAction,
  updateFormAction,
} from "../../redux/actions/form.actions";
import { updateStep } from "../../redux/actions/step.actions";

// Styles
import { env } from "../../env";
import "./styles.scss";
const Esign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const formStore = useSelector((state) => state.form);
  const [signatureBase, setSignatureBase] = useState(formStore.signature);
  const [consent, setConsent] = useState(true);
  const [signatureStarted, setSignatureStarted] = useState(false);

  const saveSign = () => {
    ref.current.on();
    let x = ref.current.toDataURL();
    setSignatureBase(x);
  };

  const handleReset = () => {
    ref.current.clear();
    setSignatureBase("");
    setSignatureStarted(false);
  };

  useEffect(() => {
    // ref.current.fromDataURL(signatureBase);
    console.log(ref);
  }, [signatureBase]);

  const handleNext = async () => {
    if (!signatureBase)
      return Notify.failure("Please provide your signature in the box below.");

    if (!consent) {
      return Notify.failure("Please check the confirmation box.");
    }
    // let data = {
    //   firstName: formStore.firstName,
    //   email: formStore.email,
    //   signatureBase: signatureBase,
    //   leadId: formStore.leadId,
    //   ...addressForm,
    //   previousAddress: prevAddresses,
    // };

    Loading.dots();
    dispatch(
      updateFormAction({
        ...formStore,
        signature: signatureBase,
      })
    );
    let data = {
      ...formStore,
      signature: signatureBase,
    };

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", env.REACT_APP_ZAPIER_WEBHOOK_URL);
      xhr.send(JSON.stringify({ data: data }));
      Loading.remove();
      dispatch(resetFormAction(true));
      dispatch(updateStep(1));
      navigate("/thank-you");
    } catch (err) {
      Loading.remove();
      Notify.failure("An error occurred, please try again later.");
    }
  };

  return (
    <Layout>
      <div className="esign-container">
        <div className="esign-inner">
          <div className="title-esign">
            <h2>Let’s finalise your claim!</h2>
            <p>
              All we need is your signature, as this will allow us to start
              working on your case on your behalf.
            </p>
          </div>

          <div className="signature-wrapper">
            {signatureBase ? (
              <p onClick={handleReset}>Clear</p>
            ) : (
              <p>
                ADD YOUR SIGNATURE BY USING EITHER YOUR MOUSE OR YOUR
                FINGER/STYLUS
              </p>
            )}
            {!signatureStarted && formStore.signature.length === 0 && (
              <div className="background-texts">
                <p>E-Signature</p>
              </div>
            )}
            <SignatureCanvas
              ref={ref}
              clearOnResize={false}
              penColor={"#04477A"}
              canvasProps={{
                className: "signature-canvas",
              }}
              onBegin={() => setSignatureStarted(true)}
              onEnd={saveSign}
            />
          </div>
          <div className="confirmation-ctn">
            <div>
              <input
                id="confirmation"
                type="checkbox"
                checked={consent}
                onChange={() => setConsent(!consent)}
              />
            </div>
            <label htmlFor="confirmation" className="confirmation">
              By proceeding with your claim, you agree to our Privacy Policy,
              Terms and Conditions and consent to being contacted One of our
              panel of solicitors or one of our affiliated partners by
              telephone, email and SMS messaging. You are free to opt-out of
              this at any time.
            </label>
          </div>
          <button className="btn-form tertiary" onClick={handleNext}>
            SUBMIT MY CLAIM
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Esign;
