import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../../../../../assets/icons/drop-arrow.svg";
import useUnload from "../../../../../helpers/useUnload";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import axios from "axios";
import { env } from "../../../../../env";
import { updateFormAction } from "../../../../../redux/actions/form.actions";
import "../styles.scss";
import { updateStep } from "./../../../../../redux/actions/step.actions";

window.predictiveAddress = {};
window.data8 = {};

const StepSeven = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Date store
  const [fullYear, setFullYear] = useState(() => {
    const arr = [];

    const startYear = 1900;
    const endYear = new Date().getFullYear() - 18;

    for (let i = endYear; i >= startYear; i--) {
      arr.push(i);
    }

    return arr;
  });
  const [fullMonth, setFullMonth] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [fullDay, setFullDay] = useState(() => {
    let days = [];

    for (let i = 1; i <= 31; i++) days.push(i);

    return days;
  });

  // Address
  const formStore = useSelector((state) => state.form);
  const [predictivePostCode, setPredictivePostCode] = useState(
    formStore?.postcode || ""
  );
  const [lookupResults, setLookupResults] = useState([]);
  const [formData, setFormData] = useState({
    title: formStore.title,
    firstName: formStore.firstName,
    lastName: formStore.lastName,
    email: formStore.email,
    phoneNumber: formStore.phone,
    dob: formStore.dob,
    addressLineOne: formStore.addressLineOne,
    postcode: formStore.postcode,
    city: formStore.city,
  });

  let dobArr = [];

  if (formStore?.dob?.includes("/")) {
    dobArr = formStore.dob.split("/");
  } else if (formStore.dob.includes("-")) {
    dobArr = formStore.dob.split("-");
  }

  const [date, setDate] = useState({
    mm: dobArr?.[1] ? dobArr[1] : "",
    dd: dobArr?.[0] ? dobArr[0] : "",
    yyyy: dobArr?.[2] ? dobArr[2] : "",
  });

  const [errorState, setErrorState] = useState({
    email: false,
    phone: false,
  });
  const [userExists, setUserExists] = useState(false);

  const [addressForm, setAddressForm] = useState({
    addressLineOne: formStore.addressLineOne,
    addressLineTwo: formStore.addressLineTwo,
    city: formStore.city,
    county: formStore.county,
    postcode: formStore.postcode,
    currentAddressYears: "",
  });

  const [consent, setConsent] = useState(true);

  useEffect(() => {
    setFormData({ ...formStore });
  }, [formStore]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({ ...formData, [name]: value });
  };

  const onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "phone") {
      let tester = false;

      tester = /^[0-9\+][0-9]*$/.test(value);

      if (tester || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useUnload();

  const handleNext = async () => {
    Notify.init({ position: "center-top" });

    if (!formData.firstName || !formData.lastName) {
      return Notify.failure("Please provide your full name.");
    }

    if (!formData.dd || !formData.mm || !formData.yyyy) {
      return Notify.failure("Please provide your date of birth.");
    }

    if (!formData.email) {
      return Notify.failure("Please provide your email.");
    }
    if (!formData.phone) {
      return Notify.failure("Please provide your number.");
    }

    if (!formData.city) {
      return Notify.failure("Please provide your city.");
    }

    if (!formData.postcode) {
      return Notify.failure("Please provide your postcode.");
    }

    if (!formData.addressLineOne) {
      return Notify.failure("Please provide your address.");
    }

    Loading.dots();

    dispatch(
      updateFormAction({
        ...formStore,
        ...formData,
        previousAddress: prevAddresses,
        currentAddressYears: addressForm.currentAddressYears,
      })
    );
    dispatch(updateStep(8));
    Loading.remove();
    navigate("/sign");
  };

  const [prevAddresses, setPrevAddresses] = useState([]);
  const [currentYears, setCurrentYears] = useState(0);
  const [prevYearsOne, setPrevYearsOne] = useState(0);
  const [prevYearsTwo, setPrevYearsTwo] = useState(0);
  const [totalYears, setTotalYears] = useState(0);
  const years = ["1", "2", "3", "4", "5+"];

  const handleYearSelect = (e) => {
    const value = e.target.value;
    const { name } = e.target;

    if (name === "current") {
      setPrevAddresses([]);
      setCurrentYears(0);
      setPrevYearsOne(0);
      setPrevYearsTwo(0);
      setAddressForm((prev) => ({
        ...prev,
        currentAddressYears: value,
      }));
    } else if (name === "0") {
      setPrevYearsOne(0);
      setPrevYearsOne(+value);
      setPrevAddresses((prev) => {
        const updatedPrevAddress = [...prev];
        updatedPrevAddress[+name].addressYears = value;
        return updatedPrevAddress;
      });
    } else {
      setPrevYearsTwo(0);
      setPrevAddresses((prev) => {
        const updatedPrevAddress = [...prev];
        updatedPrevAddress[+name].addressYears = value;
        return updatedPrevAddress;
      });
      setPrevYearsTwo(+value);
    }
  };

  useEffect(() => {
    if (prevYearsOne >= 2) {
      setPrevYearsTwo(0);
      setPrevAddresses((prev) => {
        const updatedAddress = [prev[0]];
        return updatedAddress;
      });
    }
  }, [prevYearsOne]);

  useEffect(() => {
    const newPrevAddress = {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      county: "",
      postcode: "",
      addressYears: "",
    };

    if (+addressForm.currentAddressYears === 1) {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses.push(newPrevAddress);

      setPrevAddresses(updatedAddresses);
    }
    if (+addressForm.currentAddressYears === 2) {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses.push(newPrevAddress);

      setPrevAddresses(updatedAddresses);
    }
  }, [addressForm]);

  useEffect(() => {
    if (+prevAddresses[0]?.addressYears === 1 && currentYears <= 1) {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses.push({
        addressLineOne: "",
        city: "",
        postcode: "",
        addressYears: "",
      });
      setPrevAddresses(updatedAddresses);
    }
  }, [prevAddresses[0]?.addressYears]);

  useEffect(() => {
    setCurrentYears((prev) => prev + +addressForm.currentAddressYears);
  }, [addressForm]);

  useEffect(() => {
    setTotalYears(currentYears + prevYearsOne + prevYearsTwo);
  }, [currentYears, prevYearsOne, prevYearsTwo]);

  const handleChangeAddress = (index, field, value) => {
    setPrevAddresses((prev) => {
      const addresses = [...prev];
      addresses[index][field] = value;
      console.log(addresses);
      return addresses;
    });
  };

  return (
    <div className="step">
      <div className="step-seven">
        <div className="seven-title">
          <h2>
            Good news! <br />
            You could be in-line to make a claim!
          </h2>
          <p>
            Please enter your personal details below in order to process your
            claim with us.
          </p>
        </div>

        <div className="personal-details-container">
          <div className="first-row">
            <div className="title-container">
              <div className="title-container__wrapper">
                <select
                  className="input-dropdown"
                  name="title"
                  id="title"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    {formData.title ? formData.title : "Title"}
                  </option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </select>
                <img src={arrow} alt="arrow"></img>
              </div>
            </div>

            <input
              placeholder="First Name"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              className="input-text-form"
            />
            <input
              placeholder="Last Name"
              name="lastName"
              onChange={handleChange}
              value={formData.lastName}
              className="input-text-form"
            />
          </div>
          <div className="contact-container">
            <input
              placeholder="Telephone Number"
              name="phone"
              onChange={onChange}
              error={errorState.phone}
              value={formData.phone}
              type="number"
              className="input-number-form telephone-input"
            />
            <input
              placeholder="Email"
              name="email"
              onChange={handleChange}
              error={errorState.email}
              value={formData.email}
              className="input-text-form"
            />
          </div>
          <div className="dob-text">
            <p>Date of Birth</p>
          </div>
          <div className="dob-container">
            <div className="dropdown-wrapper">
              <select
                name="dd"
                id="dd"
                onChange={handleChange}
                className={`input-dropdown ${
                  date.dd.length !== 0 && "selected"
                })}`}
              >
                <option value={`${date.dd}`} disabled selected>
                  {date.dd ? date.dd : "DD"}
                </option>
                {fullDay.map((dd, i) => {
                  return (
                    <option key={i} value={dd}>
                      {dd}
                    </option>
                  );
                })}
              </select>
              <img src={arrow} alt="arrow"></img>
            </div>
            <div className="dropdown-wrapper">
              <select
                name="mm"
                id="mm"
                onChange={handleChange}
                selected={date.mm.length !== 0}
                className="input-dropdown"
              >
                <option value={`${date.mm}`} disabled selected>
                  {date.mm ? date.mm : "MM"}
                </option>
                {fullMonth.map((mm, i) => {
                  return (
                    <option key={i} value={mm}>
                      {mm}
                    </option>
                  );
                })}
              </select>
              <img src={arrow} alt="arrow"></img>
            </div>
            <div className="dropdown-wrapper">
              <select
                name="yyyy"
                id="yyyy"
                onChange={handleChange}
                selected={date.yyyy.length !== 0}
                className="input-dropdown"
              >
                <option value={`${date.yyyy}`} disabled selected>
                  {date.yyyy ? date.yyyy : "YYYY"}
                </option>
                {fullYear.map((year, i) => {
                  return (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
              <img src={arrow} alt="arrow"></img>
            </div>
          </div>
        </div>
        <div className="address-container">
          <div className="address-row">
            <input
              placeholder="City"
              name="city"
              onChange={handleChange}
              value={formData.city}
              className="input-text-form"
            />
            <input
              placeholder="Post code"
              name="postcode"
              onChange={handleChange}
              value={formData.postcode}
              className="input-text-form"
            />
          </div>
          <input
            placeholder="Address Line 1"
            name="addressLineOne"
            onChange={handleChange}
            value={formData.address}
            className="input-text-form"
          />
        </div>

        <div className="address-container-2">
          <div className="current-address-wrapper">
            <h4>How many years have you lived at this address?</h4>
            <div className="selected-address">
              <p>
                {formData.addressLineOne} {formData?.city} {formData?.county}{" "}{formData?.postcode}
              </p>
            </div>
            <div className="year-select-wrapper">
              <select
                name="current"
                onChange={(e) => handleYearSelect(e)}
                className="year-select"
              >
                <option value="" disabled={true} selected={true}>
                  Select Years
                </option>
                {years.map((y, i) => (
                  <option key={i} value={i + 1}>
                    {y === "5" ? y + "+" : y}
                  </option>
                ))}
              </select>
              <img src={arrow} alt="arrow" />
            </div>
          </div>

          {prevAddresses.map((address, i) => (
            <div className="previous-address-wrapper" key={i}>
              <h4>Please provide a previous address</h4>
              <div className="search-row">
                <input
                  placeholder="Enter postcode"
                  name="postcode"
                  onChange={(e) =>
                    handleChangeAddress(i, "postcode", e.target.value)
                  }
                  value={address.postcode}
                  className="input-text-form"
                />
              </div>
              <input
                placeholder="Address Line 1"
                name="addressLineOne"
                onChange={(e) =>
                  handleChangeAddress(i, "addressLineOne", e.target.value)
                }
                value={address.addressLineOne}
                className="input-text-form"
              />
              <input
                placeholder="City"
                name="city"
                onChange={(e) => handleChangeAddress(i, "city", e.target.value)}
                value={address.city}
                className="input-text-form"
              />
              <div className="bottom-row">
                <h4>How many years have you lived at this address?</h4>
                <div className="year-select-wrapper">
                  <select
                    name={`${i}`}
                    onChange={(e) => handleYearSelect(e)}
                    className="year-select"
                  >
                    <option value="" disabled={true} selected={true}>
                      Select Years
                    </option>
                    {years.map((y, i) => (
                      <option key={i} value={i + 1}>
                        {y === "5" ? y + "+" : y}
                      </option>
                    ))}
                  </select>
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            </div>
          ))}
        </div>
        {totalYears >= 3 && (
          <button className="btn-form tertiary" onClick={handleNext}>
            SUBMIT MY CLAIM
          </button>
        )}
      </div>
    </div>
  );
};

export default StepSeven;
