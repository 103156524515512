import React from "react";

// Assets
import plusPng from "../../../../assets/icons/plus.svg";

// Styles
import "./styles.scss";

const FAQs = () => {
  
  const scrollTo = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  }

  const faqData = [
    {
      title: "Just how easy is it for me to claim back?",
      body: "<p>It has never been simpler to find out if you’re due compensation on your current and old finance agreements.</p><p>Start here by <button id='faq-scroll-to'>filling out our free online form</button> today!</p>",
    },
    {
      title: "How do I know if I’m entitled to compensation?",
      body: "<p>If you took out some sort of finance agreement, like a PCP agreement or HP policy in the last ten years, then you could be a victim of mis-selling and eligible to make a claim.</p><p>Find out if you could be due compensation today by <button id='faq-scroll-to'>filling out our free online form</button> now!</p>",
    },
    {
      title: "What criteria must I meet?",
      body: "<p>In order to initially qualify for making a claim through us, any of the following must have taken place:</p><ul><li>Your lender failed to alert you that they would be profiting from your policy’s commission payments.</li><li>You did know about the commissions, but weren’t fully aware about just how much money your lender was making from them. </li><li>The finer details of your finance and vehicle loans were not properly explained to you.</li><li>You were being charged higher-than-normal interest rates because of the lender’s commissions arrangement.</li></ul><p>If this criteria applies to you, then see if you could <button id='faq-scroll-to'>make a claim</button> by checking out our free, fully online form here.</p>",
    },
    {
      title:
        "Does it matter if I had a PCP agreement? Could I qualify with a HP loan?",
      body: "<p>There’s no need to worry if you didn’t take out a PCP finance agreement, as we accept claims based off a wide range of vehicle finance agreements. This includes HP loans!</p><p>Start your claim today by <button id='faq-scroll-to'>filling out our online form</button> now.</p>",
    },
  ];
  return (
    <section className="faqs-section">
      <form onSubmit={scrollTo}>
        <div className="wrapper">
          <div className="faqs-container">
            <div className="container__left">
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className="container__right">
              <ul>
                {faqData.map((obj, i) => {
                  return (
                    <li key={i}>
                      <div>
                        <img src={plusPng} alt="Plus symbol" />
                        <h5>{obj.title}</h5>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: obj.body }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default FAQs;
