import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as OpopMediaIcon } from "../../assets/icons/opop.svg";
import "./styles.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <LogoIcon className="logo" />
        {/* <div className="body-footer">
          <p>
            Car Claims Expert is trading names of Pearson Locke Ltd which is a
            limited company registered in England & Wales with registration
            number 12851926. A list of directors of the company is available for
            inspection at the registered office: 86 Deansgate, Suite 1, First
            Floor, Manchester, M3 2ER. Authorised by the Solicitors Regulation
            Authority under Registration Number 807786. VAT number 384131407
          </p>
          <ul>
            <li>
              <a href="/documents/terms.pdf" target="__blank">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="/documents/fees.pdf" target="__blank">
                Our Fees
              </a>
            </li>
            <li>
              <a href="/documents/complaints.pdf" target="__blank">
                Complaints Procedure
              </a>
            </li>
            <li>
              <a href="/documents/privacy-policy.pdf" target="__blank">
                Privacy Notice
              </a>
            </li>
            <li>
              <a href="/documents/cookie-policy.pdf" target="__blank">
                Cookie Notice
              </a>
            </li>
          </ul>
        </div> */}
        <div className="bottom-footer">
          <p>Copyright © 2023 Car Claim Expert, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};
