import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as RatedIcon } from "../../assets/icons/rated.svg";
import { ReactComponent as SecureIcon } from "../../assets/icons/secure.svg";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { resetFormAction } from "../../redux/actions/form.actions";
import { updateStep } from './../../redux/actions/step.actions';

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const navigateToHomePage = () => {
    dispatch(resetFormAction(true));
    dispatch(updateStep(1));
    navigate("/");
  }

  return (
    <header className="navbar">
      <div className={`wrapper ${location.pathname !== "/" && "blue-fill"}`}>
        <LogoIcon style={{ cursor: "pointer"}} className="logo" onClick={navigateToHomePage}/>
        <div className="left-icons">
          <RatedIcon />
          <SecureIcon />
        </div>
      </div>
    </header>
  );
};
