import { Notify } from "notiflix/build/notiflix-notify-aio";
import React, { useEffect, useState } from "react";

// Data
import { lendersData } from "./data";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateFormAction } from "../../../../../redux/actions/form.actions";
import { updateStep } from "../../../../../redux/actions/step.actions";

// Styles
import "../styles.scss";

const StepFive = () => {
  const dispatch = useDispatch();

  const formStore = useSelector((state) => state.form);
  const [formData, setFormData] = useState({ ...formStore });
  const [lenders, setLenders] = useState([]);

  useEffect(() => {
    setFormData({ ...formStore });
  }, [formStore]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({ ...formData, [name]: value });

    if (e.target.name === "lender") {
      handleSearch(value);
    }
  };

  const handleNext = () => {
    if (!formData.lender) {
      Notify.init({ position: "center-top" });

      return Notify.failure("Please enter the name of the lender.");
    }

    if (!lendersData.includes(formData.lender)) {
      Notify.init({ position: "center-top" });

      return Notify.failure("Please select a lender from the list.");
    }

    dispatch(updateFormAction(formData));

    dispatch(updateStep(4));
  };

  const handleSearch = (value) => {
    if (!value) {
      return setLenders([]);
    }

    const filtersearch = lendersData.filter((lender) => {
      const lenderName = lender.toString().toLowerCase();
      const lenderSearch = value.toString().toLowerCase();

      if (lenderName.includes(lenderSearch)) {
        return lender;
      }
    });
    setLenders(filtersearch);
  };

  const handleClick = (event) => {
    let value = event.target.getAttribute("data-value");
    let name = event.target.getAttribute("data-name");

    setFormData({ ...formData, lender: value });
    setLenders([]);
  };

  return (
    <div className="step">
      <div className="step-five">
        <p>Which lender did you purchase your car from?</p>
        <div className="step-five__input">
          <div>
            <input
              placeholder="Start typing to see the name of your lender"
              onChange={handleChange}
              name="lender"
              value={formData.lender}
              className="input-text-form"
            />
            <div className="link-five">
              <a href="/sorry">My lender isn’t included in these options</a>
            </div>
            {lenders.length > 0 && (
              <ul className="dropdown-results">
                {Array.isArray(lenders) &&
                  lenders.map((lender, i) => {
                    return (
                      <li
                        key={i}
                        onClick={handleClick}
                        data-name="lender"
                        data-value={lender}
                      >
                        {lender}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
        <button className="btn-form tertiary" onClick={handleNext}>
          NEXT QUESTION
        </button>
      </div>
    </div>
  );
};

export default StepFive;
