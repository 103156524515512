import React from "react";

// Styles
import "./styles.scss";

const WhatIs = () => {
  return (
    <section className="whatis-section">
      <div className="wrapper">
        <div className="whatis-container">
          <div className="container__left">
            <h2>What is ‘mis-sold’ PCP finance?</h2>
            <p>
              Millions of UK vehicle drivers may be due financial compensation
              after an investigation by the FCA found that they were being
              charged too much money on their monthly finance payments.
            </p>
          </div>
          <div className="container__right">
            <p>
              <span>You could qualify if:</span>
            </p>
            <ul>
              <li>
                Your lender failed to disclose the commission fees involved in
                your agreement.
              </li>
              <li>
                The interest rate attached to your agreement was based on these
                high commission fees.
              </li>
              <li>
                Your lender didn’t explain the full terms and workings of your
                car finance loan.
              </li>
            </ul>
            <button className="btn-home" onClick={() => window.scrollTo(0, 0)}>
              START YOUR FREE CHECK
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatIs;
