import React from "react";

// Styles
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "../../../../../redux/actions/step.actions";
import "../styles.scss";

const StepOne = () => {
  const dispatch = useDispatch();
  const formStore = useSelector((state) => state.form);

  const handleNext = () => {
    dispatch(updateStep(2));
  };
  

  return (
    <div className="step">
      <div className="step-one">
        <p>Please confirm the make of your vehicle:</p>
        <input
          disabled={true}
          placeholder={`${formStore.carColour} ${formStore.vehicleType} ${formStore.yearOfManufacture}`}
          className="data-display"
        />
        <button className="btn-form tertiary" onClick={handleNext}>
          NEXT QUESTION
        </button>
      </div>
    </div>
  );
};

export default StepOne;
