export const UPDATE_STEP = "UPDATE_STEP";
export const RESET_STEP = "RESET_STEP";

export const StepUpdateAction = (payload) => {
  return {
    type: UPDATE_STEP,
    payload: payload,
  };
}

export const StepResetAction = () => {
  return {
    type: RESET_STEP,
  };
}
