import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import Form from "./pages/form";
import Esign from "./pages/sign";
import AdditionalCars from "./pages/AdditionalCars";
import ThankYou from "./pages/thankyou";
import Sorry from "./pages/sorryPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/form" element={<Form />} />
      <Route path="/sign" element={<Esign />} />
      {/* <Route path="/additional-cars" element={<AdditionalCars />} /> */}
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/sorry" element={<Sorry />}/>
    </Routes>
  );
}

export default App;
